<template>
  <div class="container-fluid">
    <h1>Schichten, die in Timelkam vorkommen</h1>

    <div class="d-flex">
      <img src="/img/karte/Schichttafel.png"/>
      <div class="text-left ps-3">
        <p>Wir beginnen die Beschreibung mit den ältesten Schichten, die an der Oberfläche zu sehen sind. Es sind dies auch die markantesten
          Landschaftsformen in der Marktgemeinde: die „Schlierwände“  entstanden  durch die Eintiefung und Abtragung durch die Vöckla. Sie bestehen aus zwei Schichten: Vöckla-Formation und Atzbach-Formation.
        </p>
      </div>
    </div>


  </div>
</template>

<script>
export default {
  name: "WichtigeSchichten1"
}
</script>

<style scoped>

</style>