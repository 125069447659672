<template>
  <div class="container-fluid pt-4">

    <div class="container-fluid text-left">
      <div style="float:right;margin-left:20px;width:45%">
        <img src="/img/erdgeschichte/Oligozaen.jpg" class="img-fluid"/>
        <p class="small">Abb. aus: Rocky Austria, GBA</p>
      </div>
    </div>



    <div class="container-fluid text-left">

      <p>Erst am Beginn des Neogens (früher „Jung-Tertiär“), vor 55 Millionen Jahren „taucht“ Timelkam wieder ins Meer ein. Das Meer war nun nicht mehr der
        Penninische Ozean, sondern ein neues Randmeer, das durch die aufsteigenden Alpen vom noch jungen Mittelmeer getrennt war. Dieses neue Randmeer, das
        von Zentralasien bis Frankreich reichte, wird als Parathetys bezeichnet.</p>
      <p>Die rasche Alpenhebung förderte natürlich auch die Abtragung und so wurde das Meeresbecken durch „zermahlene“ Gesteine, vor allem Sande und Tone,
        aufgefüllt. Daher bezeichneten die Geologen das Meer auch als Molassemeer (frz. molasse = sehr weich). Auch die Gebirge im Norden – z.B. der Böhmerwald
        im Mühlviertel – werden abgetragen und helfen mit, das Meeresbecken aufzufüllen.</p>

    </div>
  </div>
</template>

<script>
export default {
  name: "Erdgeschichte3"
}
</script>

<style scoped>

</style>