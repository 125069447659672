<template>
  <div class="container-fluid text-left">
    <div class="float-end mt-3 mb-5 ms-3" style="max-width:55%">
      <img src="/img/karte/Baugrundrisiko.png" class="img-fluid"/>
      <p class="small">Aus: Geogenes Baugrundrisiko Marktgemeinde Timelkam. Land OÖ, Abt. Raumordnung (2006)</p>
    </div>

    <div>
      <p>Auf einer geologischen Karte sind die an oder nahe der Erdoberfläche vorkommenden <b>Gesteine</b> dargestellt, wenn man die bedeckende Bodenschicht entfernt. Durch Geländeuntersuchungen werden die anstehenden Gesteine räumlich erfasst und  auf einer „normalen“ (topographischen) <b>Landkarte</b> in Form von Farbflächen  eingetragen.</p>

      <p>Damit bildet die geologische Karte eine wichtige Grundlage für Planungen im technischen Bereich (Straßenbau, Bahnbau, Gebäude, Hochwasserschutz, etc.) und ist auch für die Rohstoffsuche, die Beurteilung anderer Ressourcen ( z.B. Grundwasser oder Erdwärme), sowie von bestimmten Naturgefahren (z.B. Rutschungen) wichtig.</p>
    </div>

  </div>
</template>

<script>
export default {
  name: "WasIstGeologischeKarte2"
}
</script>

<style scoped>

</style>