<template>
  <div class="d-flex flex-column justify-content-around">

    <div>
      <div class="mb-3">
        <button type="button" class="btn btn-outline-secondary" @click="$emit('change-page',{index:'Alles begann im Meer'})">
          <h2>Alles begann im Meer</h2>
        </button>
      </div>

      <div class="mb-3">
        <button type="button" class="btn btn-outline-secondary" @click="$emit('change-page',{index:'Das Meer verschwindet'})">
          <h2>Das Meer verschwindet</h2>
        </button>
      </div>

      <div class="mb-3">
        <button type="button" class="btn btn-outline-secondary" @click="$emit('change-page',{index:'Das Meer kommt zurück'})">
          <h2>Das Meer kommt zurück</h2>
        </button>
      </div>

      <div class="mb-3">
        <button type="button" class="btn btn-outline-secondary" @click="$emit('change-page',{index:'Das Meer wird aufgefüllt'})">
          <h2>Das Meer wird aufgefüllt</h2>
        </button>
      </div>

      <div class="mb-3">
        <button type="button" class="btn btn-outline-secondary" @click="$emit('change-page',{index:'Zwischen Alpenhebung und Eiszeit'})">
          <h2>Zwischen Alpenhebung und Eiszeit</h2>
        </button>
      </div>

      <div class="mb-3">
        <button type="button" class="btn btn-outline-secondary" @click="$emit('change-page',{index:'Die Eiszeiten formen die heutige Landschaft'})">
          <h2>Die Eiszeiten formen die heutige Landschaft</h2>
        </button>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "Erdgeschichte"
}
</script>

<style scoped>

</style>