<template>
  <div class="container-fluid pt-4">

    <div style="max-width:60%;float:right;margin-left:20px;">
      <img src="/img/rohstoffe/Geothermie.png" class="img-fluid"/>
      <p class="small">OBERHAUSER, R (1980): Der geologische Aufbau Österreichs. Springer, Wien. Geothermische Tiefenkarte, S 579, verändert</p>
    </div>



    <div class="container-fluid text-left">
      <p>Die Wasser, die in unserem Untergrund in den Meeresablagerungen zirkulieren,  haben eine Zusammensetzung ähnlich wie Meerwasser, etwa in Bad Hall ein
        Jod-Brom-Kochsalz-Wasser. Dass sich auch in unserem Untergrund Mineralwässer befinden, kann also angenommen werden.
      </p>

      <p>Ein anderes Problem ist  die Temperatur des Wassers. Im Durchschnitt von OÖ wird die Erde pro 100 m Tiefe um 3 – 3,5°C wärmer (geothermische
        Tiefenstufe). Allerdings ist  die Erwärmung nicht gleichmäßig verteilt. So erreicht Geinberg in 1000m Tiefe bereits 63°C. Wir liegen, wie aus der
        Karte ersichtlich in einem Temperaturtal und erreichen nur etwa 50°C (lt. Messungen der RAG).
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "BadTimelkam"
}
</script>

<style scoped>

</style>