<template>
  <div class="container-fluid pt-4">

    <div class="container-fluid text-left">
      <p>Der <b>Jüngere Deckenschotter</b> entstand während der MINDEL-Eiszeit. Sie ist die zweitälteste Kaltzeit und ebenfalls nach einem bayrischen Fluss benannt.  Sie erreichte vor 450.000 Jahren ihren Höhepunkt und dauerte etwa 60.000 Jahre.</p>

      <img src="/img/schichten/Juengere_Deckenschotter.png" class="float-start me-3" style="max-width:50%"/>
      <p>Auch der <b>Jüngere Deckenschotter</b>  wurde vielfach umgelagert.
        Das Vorkommen bei Haag wurde vom Hausruck, also von Norden her abgelagert.
        Die Vorkommen  am Koberg und auf der Anhöhe zwischen Kalchofen und Eiding zeigen anhand der Zusammensetzung ihrer Gerölle (z.B. mit Kalkstein aus den Nördlichen Kalkalpen) den Einfluss aus dem Süden.
      </p>
      <p>Bei der Eidinger Schlierwand (siehe Foto) liegt der Jüngere Deckenschotter direkt auf dem Schlier. Eine Ursache für die Kalktuffquelle.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "JuengererDeckenschotter"
}
</script>

<style scoped>

</style>