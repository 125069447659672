<template>
  <div class="container-fluid pt-4">
    <div class="container-fluid text-left">
      <div style="float:right;margin-left:20px;width:45%">
        <img src="/img/erdgeschichte/Jura.jpg" class="img-fluid"/>
        <p class="small">Abb. aus: Rocky Austria, GBA</p>
      </div>

      <p>Aus der Wissenschaft wissen wir: Kontinente wandern, Meere entstehen und schließen sich wieder (Plattentektonik).</p>

      <p>Die geologische Geschichte Timelkams beginnt vor 170 Millionen Jahren (Jura) in einem Meer am Rande des damaligen Nordkontinents <b>Laurasia</b>.
        Zwischen Laurasia und dem Südkontinent Gondwana öffnete sich der Penninische Ozean. Das ursprüngliche Meer, die Thetys schloss sich weiter östlich an.
        Den Meeresboden bildeten Granite und Gneise, wie wir sie auch vom Mühlviertel kennen (Böhmische Masse). Darüber lagerten sich in diesem tropischen
        Schelfmeer, das nur wenige 100 Meter tief war, Sandsteine und Kalke ab.</p>
      <p>Auffällige Bewohner dieses Meeres waren die Ammoniten, Verwandte der
        Tintenfische mit einer Kalkschale. Der Timelkamer <b>Oskar Irnstorfer</b> war ein begeisterter Sammler dieser Fossilien.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Erdgeschichte1"
}
</script>

<style scoped>

</style>