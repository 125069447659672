<template>
  <div class="d-flex flex-column justify-content-around">
    <div>
      <div class="mb-3">
        <button type="button" class="btn btn-outline-secondary" @click="$emit('change-page',{index:'Was ist eine geologische Karte?'})">
          <h2>Was ist eine geologische Karte?</h2>
        </button>
      </div>

      <div class="mb-3">
        <button type="button" class="btn btn-outline-secondary" @click="$emit('change-page',{index:'Was kann man aus der Karte erkennen'})">
          <h2>Was kann man aus der Karte erkennen?</h2>
        </button>
      </div>

      <div class="mb-3">
        <button type="button" class="btn btn-outline-secondary" @click="$emit('change-page',{index:'Schichten, die in Timelkam vorkommen'})">
          <h2>Wichtige Schichten</h2>
        </button>
      </div>
    </div>


  </div>
</template>

<script>
export default {
  name: "GeologieErdgeschichte"
}
</script>

<style scoped>

</style>