<template>
  <div class="container-fluid pt-4">

    <div class="container-fluid text-left">
      <h2>Was ist Quarz?</h2>

      <img src="/img/schichten/QuarzreicherKies.jpg" style="max-width:45%;float:right;margin-left:20px;"/>


      <p>Ein sehr hartes Mineral (Härte 7 von 10), chemisch Siliziumdioxid und kristallisiert als Bergkristall bekannt</p>

      <h2>Wie entstand der „Quarzreiche Kies“?</h2>

      <p>Der <b>Quarzreiche Kies im Allgemeinen</b> stammt aus einer Zeit als das Meer bereits verschwunden war.
        Vor rund  12 bis 7 Millionen Jahren breitete sich, bedingt durch die Abtragung aus den Alpen, im Alpenvorland eine große Schotterflur und daran angrenzende Sumpfgebiete (mit Kohlebildung „Hausruckkohle“) aus.
        Die Reste davon bilden heute die Höhenzüge des Hausrucks und des Kobernausser Waldes. Im langen Zeitraum  bis zum Beginn der  Eiszeiten wurden diese Ablagerungen immer weiter  abgetragen, transportiert und mehrfach umgelagert. Vor allem die härtesten Gerölle aus Quarz, Quarzit und manchen kristallinen Gesteinen überlebten diese vielfache Umlagerung. Solche quarzreichen  Kiese bedecken  z.B. die Höhen von Obereck und Altwartenburg.
      </p>

    </div>
  </div>
</template>

<script>
export default {
  name: "QuarzreicherKies"
}
</script>

<style scoped>

</style>