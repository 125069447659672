<template>
  <div class="container-fluid pt-4">
    <div class="container-fluid text-left">
      <div class="float-start me-3" style="max-width:30%">
        <img src="/img/wasser/Grundwasser_Struktur.png" class="img-fluid"/>
        <p class="small mt-3">Abb. nach: UHLMANN; HORN (2001): Hydrobiologie der Binnengewässer. Ulmer, Stuttgart</p>
      </div>

      <p>Wenn es regnet, wird ein großer Teil des Regenwassers durch unsere Bäche und Flüsse abtransportiert. Ein Teil aber versickert im Boden und dringt, der Schwerkraft folgend, nach unten.  In Gesteinen, die wie Sande, Schotter usw. nicht dicht sind, kann sich das Wasser in den kleinen Zwischenräumen (Poren) sammeln.</p>

      <p>Liegt unter dieser Schicht eine wasserdichte Schicht, z.B. aus Ton, dann staut sich das Wasser im porenhältigen Gestein als Grundwasserkörper.
        Das Wasser kann sich durch dieses Gestein auch bewegen, man spricht dann von einem Grundwasserstrom.</p>

      <p>Die Oberfläche des Grundwasserkörpers wird als Grundwasserspiegel bezeichnet.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Grundwasser"
}
</script>

<style scoped>

</style>