<template>
  <div class="d-flex flex-column justify-content-around">

    <div>
      <div class="mb-3">
        <button type="button" class="btn btn-outline-secondary" @click="$emit('change-page',{index:'Was ist Grundwasser?'})">
          <h2>Was ist Grundwasser?</h2>
        </button>
      </div>

      <div class="mb-3">
        <button type="button" class="btn btn-outline-secondary" @click="$emit('change-page',{index:'Wo finde ich Grundwasser?'})">
          <h2>Wo finde ich Grundwasser?</h2>
        </button>
      </div>

      <div class="mb-3">
        <button type="button" class="btn btn-outline-secondary" @click="$emit('change-page',{index:'Das Schlierrinnensystem'})">
          <h2>Das Schlierrinnensystem</h2>
        </button>
      </div>

      <div class="mb-3">
        <button type="button" class="btn btn-outline-secondary" @click="$emit('change-page',{index:'Artesische Brunnen'})">
          <h2>Artesische Brunnen</h2>
        </button>
      </div>

      <div class="mb-3">
        <button type="button" class="btn btn-outline-secondary" @click="$emit('change-page',{index:'Unsere Wasserversorgung'})">
          <h2>Unsere Wasserversorgung</h2>
        </button>
      </div>
    </div>


  </div>
</template>

<script>
export default {
  name: "UnserWasser"
}
</script>

<style scoped>

</style>