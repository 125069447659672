<template>
  <div class="container-fluid pt-4">

    <div class="d-flex text-left">
      <div>
        <p>Timelkam liegt an einem Grundwasserknotenpunkt: Die Vöcklaschlierrinne von Vöcklamarkt abwärts, die Dürre Ager – Rinne und die Ober Agerrinne bei der Reichen Ager vereinigen sich bei Straß und werden zur Vöcklabrucker Schlierwanne.</p>
        <h2>Was wissen wir über die Schlierrinnen im Einzelnen?</h2>
        <p>In der Vöckla- Schlierrinne folgt der Grundwasserstrom ungefähr dem Lauf der Vöckla von Vöcklamarkt abwärts.  Bei Zipf speist ein Grundwasserstrom unter dem Frankenburger Redlbach zusätzlich Wasser ein. Die Schlierrinne ist mit Wasser gut gefüllt, der Grundwasserkörper ist bis zu 20 m mächtig.  Daher kann die Energie AG für ihr Kraftwerk („Dampfkraftwerk“) reichlich Grundwasser entnehmen.</p>



      </div>
      <div class="ms-3">
        <img src="/img/wasser/Schlierrinnen.png" style="width:500px"/>
        <p class="small">Verändert nach: EGGER, Hans (2007): Erläuterungen zu Blatt 66 GMUNDEN, GBA, Wien</p>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "Schlierrinnensystem"
}
</script>

<style scoped>

</style>