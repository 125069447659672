<template>
  <div class="container-fluid pt-4">

    <div class="text-left">
      <h2>Wo entstand Schlier?</h2>

      <img src="/img/schichten/Schlier.png" style="max-width:45%;float:right"/>

      <p>Die <b>Vöckla-Formation</b> wird bei uns üblicherweise als „Schlier“ bezeichnet. Dieses grau-braune sandig-tonige Gestein ist eine Ablagerung der <a href="#" @click.prevent="$emit('change-page',{index:'Das Meer kommt zurück'})">Parathetys („Urmeer“)</a>. Die frisch aufgefalteten Alpen lieferten mit Flüssen (z.B. aus der „Ur-Salzach“) das Material dazu.</p>

      <h2>Woraus besteht Schlier? Wie hat er sich gebildet?</h2>

      <p>Der Schlier besteht zu ca. der Hälfte aus Quarzsand, ein Drittel sind Tone, der Rest ist Feldspat und etwas Kalk. Abgelagert wurde der Schlier in etwa 80 – 200 m Wassertiefe, wo die Strömungen durch die Gezeiten noch spürbar sind. Dadurch ergeben sich die für die Vöckla-Formation typischen Schichtungen. Man rechnet, dass sich in 1000 Jahren durchschnittlich ungefähr ein Meter Schlier abgelagert hat.
        Die Ablagerung ging dabei nicht gleichmäßig vor sich, der Großteil des Materials  wurde rasch wieder weggeschwemmt. Durch seinen Tongehalt dichtet der Schlier gut ab und bildet dadurch einen wichtigen Stauhorizont für das <a href="#" @click.prevent="$emit('change-page',{index:'Unser Wasser'})">Grundwasser</a>.
      </p>

    </div>



<!--
    <div class="d-flex">
      <div class="text-left ps-3">
        <h2>Wo entstand Schlier?</h2>

        <p>Die <b>Vöckla-Formation</b> wird bei uns üblicherweise als „Schlier“ bezeichnet. Dieses grau-braune sandig-tonige Gestein ist eine Ablagerung der Parathetys („Urmeer“). Die frisch aufgefalteten Alpen lieferten mit Flüssen (z.B. aus der „Ur-Salzach“) das Material dazu.</p>


      </div>

      <div>

      </div>
    </div>

    <div class="text-left">


      <p>Der Schlier besteht zu ca. der Hälfte aus Quarzsand, ein Drittel sind Tone, der Rest ist Feldspat und etwas Kalk. Abgelagert wurde der Schlier in etwa 80 – 200 m Wassertiefe, wo die Strömungen durch die Gezeiten noch spürbar sind. Dadurch ergeben sich die für die Vöckla-Formation typischen Schichtungen. Man rechnet, dass sich in 1000 Jahren durchschnittlich ungefähr ein Meter Schlier abgelagert hat.
        Die Ablagerung ging dabei nicht gleichmäßig vor sich, der Großteil des Materials  wurde rasch wieder weggeschwemmt. Durch seinen Tongehalt dichtet der Schlier gut ab und bildet dadurch einen wichtigen Stauhorizont für das <a href="#" @click.prevent="$emit('change-page',{index:'Unser Wasser'})">Grundwasser</a>.
      </p>
    </div>
-->

  </div>
</template>

<script>
export default {
  name: "Voecklaformation"
}
</script>

<style scoped>

</style>