<template>
  <div class="container-fluid pt-4">

    <div class="container-fluid text-left">
      <div style="float:right;margin-left:20px;width:45%">
        <img src="/img/erdgeschichte/Kreide.jpg" class="img-fluid"/>
        <p class="small">Abb. aus: Rocky Austria, GBA</p>
      </div>

      <p>Gegen Ende des darauffolgenden Zeitabschnitts, der Kreide, wurde der Boden angehoben und ein Großteil der Schichten wurden abgetragen,
        so dass wir von den mächtigen Jura-Schichten nur mehr Reste finden.</p>
      <p>So wissen wir über rund 100 Millionen Jahren wenig Bescheid und können nur aus anderen Bohrkernen in OÖ die Geschichte rekonstruieren.</p>

      <p>Das Meer zog sich in dieser Zeit nach Süden zurück. In Gosau findet man viele Fossilien aus dieser Zeit in einen warmen tropischen Meer &ndash; dem Gosaumeer</p>

    </div>

  </div>
</template>

<script>
export default {
  name: "Erdgeschichte2"
}
</script>

<style scoped>

</style>