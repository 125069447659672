<template>
  <div class="container-fluid">

    <div class="container-fluid text-left">
      <div class="row">
        <div class="col">
          <div class="text-center mb-3">
            <img src="/img/erdgeschichte/Gletscher2.png" style="max-width:70%"/>
          </div>
          <p>Während der vier Eiszeiten befand sich Timelkam im Nahbereich der Gletscher. So reichte der
            Riß-Gletscher bis Koberg, die Endmoräne des Würm-Gletschers finden wir bei Schörfling und Seewalchen. Übrig blieben diesen Moränen anschließende Flussterrassen, namentlich der Hochterrasse aus dem Riss und der breiten Niederterrasse aus dem Würm.</p>

        </div>
        <div class="col">
          <p>Nach dem Höhepunkt der Würmvereisung begannen die Flüsse die Niederterrasse schrittweise zu zerschneiden; zahlreiche, häufig nicht durchgehend
            verfolgbare, niedrige Erosionskanten im Bereich der unteren Niederterrassenstufe und der holozänen Flussniederungen legen davon Zeugnis ab.</p>
          <div class="text-center">
            <img src="/img/erdgeschichte/Erosionskanten_AI.png" class="img-fluid"/>
          </div>
        </div>
      </div>




    </div>
  </div>
</template>

<script>
export default {
  name: "HeutigeLandschaft"
}
</script>

<style scoped>

</style>