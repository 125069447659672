<template>
  <div class="container-fluid pt-4">

    <div class="text-left">
      <p>Liegt ein Grundwasserkörper - wie ein Hamburger zwischen zwei Semmelhälften – zwischen zwei wasserdichten Schichten, gerät er unter Druck („gespanntes Wasser“). Entnimmt man Wasser nun unter dem Grundwasserspiegel, so spritzt das Wasser von selbst heraus.  Der Name stammt aus der belgischen Landschaft Artois, dort wurde 1126 n. Chr. der erste Brunnen dieser Art gebohrt.</p>
    </div>

    <div class="row mt-4">
      <div class="col">
        <img src="/img/wasser/Artesische_Brunnen_1.png" class="img-fluid"/>
        <p class="small">Schema eines artesischen Brunnens. Verändert nach: MURAWSKI, H. (1972): Geologisches Wörterbuch. Enke, Stuttgart</p>
      </div>
      <div class="col">
        <img src="/img/wasser/Artesische_Brunnen_2.png" class="img-fluid mb-2"/>
        <p class="small">Bad Weinberg bei Eferding</p>
      </div>
      <div class="col">
        <img src="/img/wasser/Artesische_Brunnen_3.png" class="img-fluid mb-2"/>
        <p class="small">Rote Punkte: Artesische Brunnen bei Leidern, Stöfling, Jochling</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ArtesischeBrunnen"
}
</script>

<style scoped>

</style>