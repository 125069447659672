<template>
  <div class="container-fluid pt-4">
    <h1>Hier erfährst du etwas...</h1>

    <div class="d-flex flex-column justify-content-between">
      <div class="mt-5 mb-5">
        <button type="button" class="btn btn-outline-secondary" @click="$emit('change-page',{index:'Erklärungen zur geologischen Karte'})">
          <h2>zur geologischen Karte</h2>
        </button>
      </div>

      <div class="mb-5">
        <button type="button" class="btn btn-outline-secondary" @click="$emit('change-page',{index:'Erdgeschichte'})">
          <h2>zur Erdgeschichte</h2>
        </button>
      </div>

      <div class="mb-5">
        <button type="button" class="btn btn-outline-secondary" @click="$emit('change-page',{index:'Rohstoffe'})">
          <h2>zu den Rohstoffen</h2>
        </button>
      </div>

      <div>
        <button type="button" class="btn btn-outline-secondary" @click="$emit('change-page',{index:'Unser Wasser'})">
          <h2>zu unserer Wasserversorgung</h2>
        </button>
      </div>
    </div>





  </div>
</template>

<script>
export default {
  name: "ErklaerungenGeologischeKarte"
}
</script>

<style scoped>

</style>