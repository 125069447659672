<template>
  <div class="container-fluid text-left">
    <div class="d-flex text-left">
      <div>
        <p>Die Dürre-Ager-Rinne erreicht bei Weiterschwang das Timelkamer Gemeindegebiet.  Sie beginnt im Frankenmarkter Raum. Das Grundwassser aus dem Oberlauf der Dürren Ager wird nach St. Georgen Richtung Attersse in die Randrinne abgeleitet. Auch wenn der Grundwasserkörper nicht so mächtig ist, wie der in der Vöcklarinne,  die Gemeindegebiete westlich der Ager (OKA-Siedlung, Voitlau usw.) werden durch ihn versorgt.</p>

        <p>Die Obere Agerrinne erreicht unter Arnbruck unser Gemeindegebiet. Sie „verliert“ im weiteren Verlauf Wasser Richtung Aurach und kommt relativ schwach nach Straß. Dort wird sie, von den mächtigeren Strömen in den Süden der Vöcklabrucker Schlierwanne (Dürnau) gedrückt.</p>
      </div>
      <div class="ms-3">
        <img src="/img/wasser/Schlierrinnen.png" style="width:500px"/>
        <p class="small">Verändert nach: EGGER, Hans (2007): Erläuterungen zu Blatt 66 GMUNDEN, GBA, Wien</p>
      </div>
    </div>
    <p>Die Vöcklabrucker Schlierwanne reicht von Straß bis nach Attnang-Puchheim. Sie ist etwa 2 km breit und der Grundwasserstrom ist 10 m mächtig.  Der Grundwasserabfluss kann bis zu 2000 l/sec betragen.</p>


  </div>
</template>

<script>
export default {
  name: "Schlierrinnensystem2"
}
</script>

<style scoped>

</style>