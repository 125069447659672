<template>
  <div class="container-fluid pt-4">
    <div class="container-fluid text-left">
      <div class="d-flex">
        <div>
          <p>Nachdem der 1956 der Brunnen Kalchofen  und der Wasserhochbehälter fertig gestellt waren, begann man 1957 mit dem Bau der  Gemeindewasserleitung. 1962 wurde der erste Brunnen in Stöfling errichtet, 1966 folgte ein Tiefbrunnen in Ader und 1984 wurde der Brunnen Stöfling 2 errichtet.</p>

          <p>Mit dem Beitritt zum Wasserverband Vöckla-Ager (1972) kam es dann zu Vernetzungen mit den Lenzinger und Vöcklabrucker Wasserleitungsnetzen.
            Im Endausbau sind 95% der Bevölkerung an das Wassernetz der Gemeinde Timelkam angeschlossen.</p>
        </div>
        <div class="ms-3">
          <img src="/img/wasser/SteuerschemaWasserversorgung.jpg" style="width:600px"/>
        </div>


      </div>


    </div>
  </div>
</template>

<script>
export default {
  name: "UnsereWasserversorgung"
}
</script>

<style scoped>

</style>