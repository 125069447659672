<template>
  <div class="container-fluid pt-4">

    <img src="/img/schichten/Niederterrasse.jpg" style="max-width:50%;float:right;margin-left:20px;"/>

    <div class="container-fluid text-left">
      <p>Die jüngste Eiszeit, die Würm-Eiszeit, begann vor 70.000 Jahren und endete vor 10.000 Jahren.  Die Gletscher reichten mit ihren Endmoränen bis Schörfling und Seewalchen.
      </p>

      <p>Die <b>Niederterrasse</b> entstand während der WÜRM-Eiszeit. Ihr Schotterkörper nimmt große Teile des Timelkamer Gemeindegebietes ein. Die mächtige Schotterfläche reicht von Maierhof bis Leidern und von Oberthalheim bis Gallaberg und wird von der Reichen Ager und der Dürren Ager zerschnitten. Neben Geröllen aus Flyschsandstein und Kalksteinen finden wir auch wieder die  mehrfach umgelagerten, schwer verwittertbaren harten Quarz- und Quarzitgerölle. Da die Ablagerungen der Niederterasse ein hervorragendes Baumaterial darstellen, gibt es von Timelkam über Regau bis Redlham immer wieder Schottergruben.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Niederterrasse"
}
</script>

<style scoped>

</style>