<template>
  <div class="museum d-flex flex-column">
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
      <div class="container-fluid justify-content-start">
        <div class="d-flex">
          <button class="btn" type="button" @click="showMenu">
            <span class="navbar-toggler-icon"></span>
          </button>

          <div class="navbar-brand"><img src="/img/wappen_timelkam.png" class="img-fluid" style="max-height:30px"/> Heimatmuseum Timelkam</div>

        </div>
        <div class="title mx-5">{{ currentTitle }}</div>
      </div>
    </nav>

    <div class="d-flex flex-column flex-grow-1">
      <div class="menu" :style="'left:'+menuX">
        <div class="row">
          <div class="col">
            <div class="overflow-scroll">
              <TreeMenu :item="tree" @change-page="changePage" :current-index="currentIndex"></TreeMenu>
            </div>

          </div>
        </div>
      </div>

        <div class="upperNavigation">
          <div class="row">
            <div class="col text-center">
              <div v-if="findUpperSibling(currentItem.title)">
                <a href="#" class="navigationIcon" @click="changePage({index:findUpperSibling(currentItem.title)})"><font-awesome-icon :icon="['fas','arrow-circle-up']"></font-awesome-icon></a> <a href="#" class="navigationIcon" @click="changePage({index:findUpperSibling(currentItem.title)})"><span class="small">{{ findUpperSibling(currentItem.title) }}</span></a><br/>
              </div>
            </div>
          </div>
        </div>

        <div class="main d-flex flex-fill flex-row">
            <div class="d-flex align-items-center">
              <div v-if="parentIndex != null">
                <a href="#" class="navigationIcon" @click="changePage({index:parentIndex})"><font-awesome-icon :icon="['fas','arrow-circle-left']"></font-awesome-icon></a><br/>
              </div>
            </div>
            <div class="d-flex flex-grow-1 justify-content-center" v-hammer:swipe="(event)=>onSwipe(event)">
              <component :is="currentView" @change-page="changePage"></component>
            </div>
            <div class="d-flex align-items-center">
              <div v-if="currentItem.children.length > 0">
                <a href="#" class="navigationIcon" @click="changePage({index:currentItem.children[0].title})"><font-awesome-icon :icon="['fas','arrow-circle-right']"></font-awesome-icon></a><br/>
              </div>
            </div>

        </div>

        <div class="lowerNavigation">
          <div class="row">
            <div class="col text-center">
              <span v-if="debug">{{ direction}}</span>
              <div v-if="findLowerSibling(currentItem.title)">
                <a href="#" class="navigationIcon" @click="changePage({index:findLowerSibling(currentItem.title)})"><font-awesome-icon :icon="['fas','arrow-circle-down']"></font-awesome-icon> <span class="small">{{ findLowerSibling(currentItem.title) }}</span></a><br/>
              </div>
            </div>
          </div>
        </div>
    </div>

  </div>

</template>

<script>
    import TreeMenu from './TreeMenu';
    import ErklaerungenGeologischeKarte from "@/components/subComponents/ErklaerungenGeologischeKarte";
    import GeologieErdgeschichte from "@/components/subComponents/GeologieErdgeschichte";
    import WasIstGeologischeKarte1 from "@/components/subComponents/GeologieErdgeschichte/WasIstGeologischeKarte1";
    import WasIstGeologischeKarte2 from "@/components/subComponents/GeologieErdgeschichte/WasIstGeologischeKarte2";
    import WasKannManErkennen from "@/components/subComponents/GeologieErdgeschichte/WasKannManErkennen";
    import WichtigeSchichten1 from "@/components/subComponents/GeologieErdgeschichte/WichtigeSchichten1";
    import Voecklaformation from "@/components/subComponents/GeologieErdgeschichte/Schichten/Voecklaformation";
    import Atzbachformation from "@/components/subComponents/GeologieErdgeschichte/Schichten/Atzbachformation";
    import QuarzreicherKies from "@/components/subComponents/GeologieErdgeschichte/Schichten/QuarzreicherKies";
    import AeltererDeckenschotter from "@/components/subComponents/GeologieErdgeschichte/Schichten/AeltererDeckenschotter";
    import JuengererDeckenschotter from "@/components/subComponents/GeologieErdgeschichte/Schichten/JuengererDeckenschotter";
    import Hochterrasse from "@/components/subComponents/GeologieErdgeschichte/Schichten/Hochterrasse";
    import Niederterrasse from "@/components/subComponents/GeologieErdgeschichte/Schichten/Niederterrasse";
    import Holozaen from "@/components/subComponents/GeologieErdgeschichte/Schichten/Holozaen";
    import Erdgeschichte1 from "@/components/subComponents/Erdgeschichte/Erdgeschichte1";
    import Erdgeschichte2 from "@/components/subComponents/Erdgeschichte/Erdgeschichte2";
    import Erdgeschichte3 from "@/components/subComponents/Erdgeschichte/Erdgeschichte3";
    import Erdgeschichte4 from "@/components/subComponents/Erdgeschichte/Erdgeschichte4";
    import Erdgeschichte5 from "@/components/subComponents/Erdgeschichte/Erdgeschichte5";
    import HeutigeLandschaft from "@/components/subComponents/Erdgeschichte/HeutigeLandschaft";
    import Rohstoffe from "@/components/subComponents/Rohstoffe";
    import Bohrungen from "@/components/subComponents/Rohstoffe/Bohrungen";
    import BadTimelkam from "@/components/subComponents/Rohstoffe/BadTimelkam";
    import UnserWasser from "@/components/subComponents/UnserWasser";
    import Grundwasser from "@/components/subComponents/UnserWasser/Grundwasser";
    import GrundwasserFinden from "@/components/subComponents/UnserWasser/GrundwasserFinden";
    import Schlierrinnensystem from "@/components/subComponents/UnserWasser/Schlierrinnensystem";
    import Schlierrinnensystem2 from "@/components/subComponents/UnserWasser/Schlierrinnensystem2";
    import ArtesischeBrunnen from "@/components/subComponents/UnserWasser/ArtesischeBrunnen";
    import UnsereWasserversorgung from "@/components/subComponents/UnserWasser/UnsereWasserversorgung";
    import BrunnenKalchofen from "@/components/subComponents/UnserWasser/BrunnenKalchofen";
    import BrunnenStoefling from "@/components/subComponents/UnserWasser/BrunnenStoefling";
    import Erdgeschichte from "@/components/subComponents/Erdgeschichte/Erdgeschichte";
    import WasIstGeologischeKarte3 from "@/components/subComponents/GeologieErdgeschichte/WasIstGeologischeKarte3";

    export default {
        name: "MuseumGeologie",
        components: {
          TreeMenu, ErklaerungenGeologischeKarte, GeologieErdgeschichte, WasIstGeologischeKarte1, WasIstGeologischeKarte2,
          WasKannManErkennen, WichtigeSchichten1, Voecklaformation, Atzbachformation, QuarzreicherKies, AeltererDeckenschotter,
          JuengererDeckenschotter, Hochterrasse, Niederterrasse, Holozaen, Erdgeschichte1, Erdgeschichte2, Erdgeschichte3,
          Erdgeschichte4, Erdgeschichte5, HeutigeLandschaft, Rohstoffe, Bohrungen, BadTimelkam, UnserWasser, Grundwasser,
          GrundwasserFinden, Schlierrinnensystem, Schlierrinnensystem2, ArtesischeBrunnen, UnsereWasserversorgung, BrunnenKalchofen,
          BrunnenStoefling, Erdgeschichte, WasIstGeologischeKarte3
        },
        data: function() {
            return {
                currentView: null,
                message: 'test',
                tree: {
                    "title": "Einleitung",
                    "component": "ErklaerungenGeologischeKarte",
                    "children": [
                        {
                            "title": "Erklärungen zur geologischen Karte",
                            "component": "GeologieErdgeschichte",
                            "children": [
                                {
                                    "title":"Was ist eine geologische Karte?",
                                    "component":"WasIstGeologischeKarte1",
                                    "children":[]
                                },
                                {
                                  "title":"Was ist eine geologische Karte 2",
                                  "component":"WasIstGeologischeKarte2",
                                  "children":[],
                                  "hideMenu": true
                                },
                              {
                                "title":"Was ist eine geologische Karte 3",
                                "component":"WasIstGeologischeKarte3",
                                "children":[],
                                "hideMenu": true
                              },
                                {
                                    "title":"Was kann man aus der Karte erkennen",
                                    "component":"WasKannManErkennen",
                                    "children":[]
                                },
                                {
                                    "title":"Schichten, die in Timelkam vorkommen",
                                    "component": "WichtigeSchichten1",
                                    "children":[
                                        {
                                            "title": "Vöcklaformation",
                                            "component": "Voecklaformation",
                                            "children":[]
                                        },
                                        {
                                            "title": "Atzbach-Formation",
                                            "component": "Atzbachformation",
                                            "children":[]
                                        },
                                        {
                                            "title":"Quarzreicher Kies",
                                            "component": "QuarzreicherKies",
                                            "children":[]
                                        },
                                        {
                                            "title":"Älterer Deckenschotter",
                                            "component": "AeltererDeckenschotter",
                                            "children":[]
                                        },
                                        {
                                            "title":"Jüngerer Deckenschotter",
                                            "component": "JuengererDeckenschotter",
                                            "children":[]
                                        },
                                        {
                                            "title":"Hochterrasse",
                                            "component": "Hochterrasse",
                                            "children":[]
                                        },
                                        {
                                            "title":"Niederterrasse",
                                            "component": "Niederterrasse",
                                            "children":[]
                                        },
                                        {
                                            "title":"Holozän",
                                            "component": "Holozaen",
                                            "children":[]
                                        }
                                    ]
                                },
                            ]
                        },
                        {
                            "title": "Erdgeschichte",
                            "component": "Erdgeschichte",
                            "children": [
                              {
                                "title": "Alles begann im Meer",
                                "component": "Erdgeschichte1",
                                "children":[]
                              },
                              {
                                "title":"Das Meer verschwindet",
                                "component": "Erdgeschichte2",
                                "children":[]
                              },
                              {
                                "title":"Das Meer kommt zurück",
                                "component": "Erdgeschichte3",
                                "children":[]
                              },
                              {
                                "title":"Das Meer wird aufgefüllt",
                                "component": "Erdgeschichte4",
                                "children":[]
                              },
                              {
                                "title":"Zwischen Alpenhebung und Eiszeit",
                                "component": "Erdgeschichte5",
                                "children":[]
                              },
                              {
                                "title":"Die Eiszeiten formen die heutige Landschaft",
                                "component": "HeutigeLandschaft",
                                "children":[]
                              }
                            ]
                        },
                        {
                            "title":"Rohstoffe",
                            "component": "Rohstoffe",
                            "children":[
                                {
                                    "title": "Erdöl und Erdgas",
                                    "component": "Bohrungen",
                                    "children":[]
                                },
                                {
                                    "title":"Bad Timelkam?",
                                    "component": "BadTimelkam",
                                    "children":[]
                                }
                            ]
                        },
                        {
                            "title":"Unser Wasser",
                            "component": "UnserWasser",
                            "children": [
                                {
                                    "title":"Was ist Grundwasser?",
                                    "component": "Grundwasser",
                                    "children":[]
                                },
                              {
                                "title":"Wo finde ich Grundwasser?",
                                "component": "GrundwasserFinden",
                                "children":[]
                              },
                              {
                                  "title":"Das Schlierrinnensystem",
                                  "component": "Schlierrinnensystem",
                                  "children":[]
                              },
                              {
                                "title":"Das Schlierrinnensystem 2",
                                "component": "Schlierrinnensystem2",
                                "children":[],
                                "hideMenu": true
                              },
                              {
                                  "title": "Artesische Brunnen",
                                  "component": "ArtesischeBrunnen",
                                  "children":[]
                              },
                              {
                                  "title":"Unsere Wasserversorgung",
                                  "component": "UnsereWasserversorgung",
                                  "children":[
                                      {
                                          "title":"Brunnen Kalchofen",
                                          "component": "BrunnenKalchofen",
                                          "children":[]
                                      },
                                      {
                                          "title":"Brunnen Stöfling I und II",
                                          "component": "BrunnenStoefling",
                                          "children":[]
                                      }
                                  ]
                              }
                            ]
                        }
                    ]
                },
                currentItem: null,
                currentIndex: null,
                parentIndex: null,
                siblings:[],
                content: null,
                direction:0,
                menuX: -500,
                debug: false,
                currentTitle: ''

            }

        },
        methods: {
            changePage(event) {
                console.log(event);
                window.console.log("Change page to: "+event.index);
                this.menuX = -500;
                this.currentIndex = event.index;
                this.currentItem = this.find(this.tree,event.index);
                this.currentView = this.currentItem.component;
                this.currentTitle = this.currentItem.title;
                // TODO ugly
                if(event.index === "Einleitung") {
                  this.parentIndex = null;
                  this.siblings = [];
                }
            },
            find(obj,index) {
                if(obj.title == index) {
                    this.content = obj;
                    return obj;
                }
                for(let child of obj.children) {
                    this.parentIndex = obj.title;
                    this.siblings = obj.children;
                    let res = this.find(child,index);
                    if(res) {
                        return res;
                    }
                }
            },
            findUpperSibling(index) {
                if(this.siblings != 0 && this.siblings.length > 0) {
                    for(let k in this.siblings) {
                        if(this.siblings[k].title == index && k > 0) {
                            return this.siblings[k-1].title;
                        }
                    }
                    return false;
                }
            },
            findLowerSibling(index) {
                if(this.siblings != null && this.siblings.length > 0) {
                    for(let k in this.siblings) {
                        if(this.siblings[k].title == index && k < this.siblings.length-1) {
                            let j = parseInt(k)+1;
                            return this.siblings[j].title;
                        }
                    }
                    return false;
                }
            },
            onSwipe(event) {
                //window.console.log("swipe");
                //window.console.log(event);
                this.direction = event.type+' '+event.direction;
                if(event.type === 'swipe') {
                    switch(event.direction) {
                        case 2:
                            if(this.currentItem.children.length > 0) {
                                //this.load(this.currentItem.children[0].title)
                                this.changePage({index:this.currentItem.children[0].title});
                            }
                            break;
                        case 4:
                            if(this.parentIndex != null) {
                                //this.load(this.parentIndex);
                                this.changePage({index:this.parentIndex});
                            }
                            break;
                        case 8:
                            if(this.findLowerSibling(this.currentItem.title)) {
                                //this.load(this.findLowerSibling(this.currentItem.title));
                                this.changePage({index:this.findLowerSibling(this.currentItem.title)});
                            }
                            break;
                        case 16:
                            if(this.findUpperSibling(this.currentItem.title)) {
                                //this.load(this.findUpperSibling(this.currentItem.title));
                                this.changePage({index:this.findUpperSibling(this.currentItem.title)});
                            }
                            break;
                    }
                }
            },
            showMenu() {
              if(this.menuX < 0) {
                this.menuX = 0;
              } else {
                this.menuX = -500;
              }
            }
        },
        created() {
            this.changePage({index:"Einleitung"});
        }
    }
</script>

<style>
    .content {
        font-size:24px;
    }
    .content p {
        text-align:left;
    }
    .navigationLink {
        font-size:12px;
    }
    a.navigationIcon {
        font-size:26px;
        color:#cfcfcf;
    }
    .content h1 {
        text-align:center;
        margin-bottom:3rem;
    }

    .menu {
      position: absolute;
      background-color:#fff;
      padding:0 10px 10px 10px;
      text-align: left;
      border-right-width: 1px;
      border-right-style: solid;
      border-right-color:#cfcfcf;
      transition: 0.5s;
      left:-500px;
    }

    .menu ul {
      list-style-type:none;
    }

    td {
      text-align:left;
    }

    .title {
      font-size: 1.25rem;
      font-weight: bold;
    }

    .lowerNavigation a, .upperNavigation a {
      text-decoration:none;
    }

</style>