<template>
  <div class="container-fluid pt-4">
    <div class="container-fluid text-left">
      <div class="d-flex">
        <div>
          <h2>Pech gehabt!</h2>
          <p>Die Schichten im ehemaligen Meeresbecken des Molassemeeres erwiesen sich als Fundgrube für energieliefernde Bodenschätze. Die Kohlenvorkommen im
            Hausruck sind zwar erschöpft, aber durch Bohrungen konnten Erdöl und Erdgas gefördert werden.</p>
          <p>Auch im Timelkamer Gemeindegebiet gab es eine Reihe von Bohrungen, die allerdings keine großen Öl- oder Gasfelder aufschlossen, aber wir wissen
            dadurch über den Timelkamer Untergrund und über die Erdgeschichte gut Bescheid.</p>
          <p>Gleich an der Gemeindegrenze zu Gampern liegt der Erdgasspeicher der RAG, Puchkirchen-Haag. Dort können in 1600m Tiefe runde 1,1 Milliarden Kubikmeter Erdgas gespeichert werden. Mehr Informationen finden Sie bei der Tafel &bdquo;Bohrprofil&ldquo;.
          </p>
        </div>


        <img src="/img/rohstoffe/Rohstoffbohrungen.png" class="ms-3" />
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "Bohrungen"
}
</script>

<style scoped>

</style>