<template>
  <div class="container-fluid">
    <h1>Brunnen Stöfling I und II</h1>
    <div class="container-fluid text-left">

        <div>
          <p>Brunnen Stöfling I ist 20 m tief, Brunnen Stöfling II ist 27 m tief.
            Geologisch liegen beide Brunnen im Vöckla-Schlier, allerdings in den jüngeren, sandigen Anteilen des Schliers, so dass Bedingungen wie bei einem guten Porengrundwasserleiter herrschen.
          </p>

          <p>Als Einzugsgebiet sind die Höhen südwestlich der Brunnen anzusehen. Also die Gebiete um Bergham und Baumgarting. Der Grundwasserstrom selbst hat ein Gefälle von 3,2% und er fließt nach Nordosten.
          </p>
        </div>
        <div class="d-flex justify-content-center">
          <div class="text-center">
            <img src="/img/wasser/BrunnenStoefling2.jpg" style="width:80%"/>
            <p>Brunnen Stöfling 1</p>
          </div>
          <div class="ms-4 text-center">
            <img src="/img/wasser/BrunnenStoefling2a.jpg" style="width:80%"/>
            <p>Brunnen Stöfling 2</p>
          </div>
        </div>


    </div>
  </div>
</template>

<script>
export default {
  name: "BrunnenStoefling"
}
</script>

<style scoped>

</style>