<template>
  <div class="container-fluid pt-4">

    <div class="container-fluid text-left">
      <h2>Wie entstanden die &bdquo;Atzbacher Sande&ldquo;?</h2>

      <img src="/img/schichten/AtzbacherSande.jpg" style="max-width:50%;float:right;margin-left:20px;"/>

      <p>Die <b>Atzbach-Formation</b> („Atzbacher Sande“ oder „Schliersand“) wurde über der Vöckla-Formation in einem bereits seichteren Meer abgelagert. Die Grenze zwischen den beiden Formationen kann im Gemeidegebiet nicht scharf gezogen werden, der Übergang zu den stärker sandig dominierten Ablagerungen vollzieht sich fließend.
        Der Sand besteht hauptsächlich aus Quarz, wenig Feldspat, Kalk und Dolomit. Das Material stammt aus einem Mündungsdelta der „Ursalzach“ und wurde durch die Gezeiten des Meeres hierher transportiert.
      </p>
    </div>

  </div>
</template>

<script>
export default {
  name: "Atzbachformation"
}
</script>

<style scoped>

</style>