<template>
  <div class="container-fluid pt-4">

    <div style="float:right;width:70%">
      <img src="/img/erdgeschichte/Paratethys.png" class="img-fluid"/>
    </div>


    <div class="container-fluid text-left">
      <p>Über rund 40 Mill. Jahre wird dieses Meeresbecken nun mit fast 3000 m mächtigen Sedimenten aufgefüllt. Zuerst werden die groben Sande und
        Tone der Puchkirchen-Formation von Süden her eingebracht. Die darauf folgende Hall-Formation ist dann schon feinsandiger und zeigt mergelige Anteile.</p>
      <p>Als letztes lagerten sich die Vöckla-Formation und die Atzbach-Formation ab (siehe <a href="#" @click.prevent="$emit('change-page',{index:'Schichten, die in Timelkam vorkommen'})">Wichtige Schichten</a>). Vor ungefähr 17 Millionen Jahren hatte sich das Meer nach Osten
        zurückgezogen und erst nach einer knapp 5 Millionen Jahre umfassenden Schichtlücke setzte mit den Süßwasserablagerungen des Hausruck- und
        Kobernaußerwaldes (Schotter, Sande, Tone und Kohle) ein neuer Ablagerungsserie ein.</p>

    </div>
  </div>
</template>

<script>
export default {
  name: "Erdgeschichte4"
}
</script>

<style scoped>
  a {
    text-decoration: underline;
    color: var(--bs-body-color);
  }
</style>