import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import VueHammer from "./VueHammer"

import styles from './css/styles.css';

import { library } from "@fortawesome/fontawesome-svg-core";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faArrowCircleLeft} from "@fortawesome/free-solid-svg-icons";
import { faArrowCircleRight} from "@fortawesome/free-solid-svg-icons";
import { faArrowCircleUp} from "@fortawesome/free-solid-svg-icons";
import { faArrowCircleDown} from "@fortawesome/free-solid-svg-icons";

library.add(faArrowCircleLeft);
library.add(faArrowCircleRight);
library.add(faArrowCircleUp);
library.add(faArrowCircleDown);

const app = createApp(App)
    .use(VueHammer)
    .use(styles)
    .component("font-awesome-icon",FontAwesomeIcon);
app.mount('#app');
