<template>
  <div class="container-fluid pt-4">
    <div class="container-fluid text-left">
      <p>Der <b>Ältere Deckenschotter</b> ist unsere älteste Eiszeitablagerung. Die Günz-Eiszeit, benannt nach einem bayrischen Fluss erreichte ihren Höhepunkt vor etwa 650.000 Jahren und dauerte über 100.000 Jahre an.</p>

      <p>Der <b>Ältere Deckenschotter</b> wurde in dieser langen Zeit vielfach umgelagert und transportiert. Es gibt im Kartenausschnitt nur wenige Vorkommen auf den Höhen westlich des Ampflwanger Baches. Hauptsächlich sind es Quarz- und Quarzitschotter. Quarzit ist ein Umwandlungsgestein, das fast nur aus Quarz besteht.</p>

    </div>
  </div>
</template>

<script>
export default {
  name: "AeltererDeckenschotter"
}
</script>

<style scoped>

</style>