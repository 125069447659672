<template>
  <div class="container-fluid">
    <h1>Brunnen Kalchofen</h1>
    <div class="container-fluid text-left">
      <div class="d-flex">
        <div>
          <p>Der Brunnen Kalchofen wurde 1952 wasserrechtlich bewilligt.
            Er ist 28 m tief, der Grundwasserspiegel liegt bei  ca 11m Tiefe.
            Bohrprofil: Unter einer 4m mächtigen Schotterauflage beginnt zuerst sandiger Schlier und dann etwa ab 8m Tiefe folgt blauer, sandiger Schlier der mehr Tonanteile enthält. Da der Schlier sehr kompakt ist, konnte der untere Teil des Brunnens als freistehender Schlierbrunnen ausgeführt werden.
          </p>

          <p>Das Grundwasser – Einzugsgebiet erstreckt sich in nordöstlicher Richtung, also in Richtung  Altwartenburg – Außerhafling.  Das Wasser strömt nach Süden Richtung Vöckla, die das Grundwasser dann zurückstaut.
          </p>
        </div>

        <div class="ms-3">
          <img src="/img/wasser/BrunnenKalchofen.jpg" style="width:600px"/>
        </div>

      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "BrunnenKalchofen"
}
</script>

<style scoped>

</style>