<template>
   <ul>
    <li v-if="item.hideMenu !== true">
      <a v-if="currentIndex !== item.title" href="#" @click="$emit('change-page',{index:item.title})">{{ item.title }}</a>
      <span v-else>{{ item.title }}</span>
    </li>
      <TreeMenu v-for="(child,key) in item.children" :item="child" :key="key" @change-page="eventProxy" :current-index="currentIndex"></TreeMenu>
  </ul>
</template>

<script>
export default {
  name: "TreeMenu",
  props: {
    item: Object,
    currentIndex: String
  },
  methods: {
    eventProxy(event) {
      this.$emit('change-page',event);
    }
  }
}
</script>

<style scoped>

</style>