<template>
  <div class="container-fluid text-left">
    <div class="float-end text-end mx-3" style="width:33%;">
      <img src="/img/karte/GeokarteLegende.png" class="img-fluid"/>
    </div>
    <div class="justify-content-between align-items-center mt-4 mb-4">
      <h2>Was bedeuten die Farben?</h2>
      <p>Die Farben geben dabei meist schon Auskunft, um welche Art von Gestein es sich handelt (Sedimente, kristalline Gesteine o.ä.).
        Damit man die einzelnen Farben in der Karte besser mit der zugehörigen Kartenlegende in Beziehung setzen kann, sind sie auch mit Nummern versehen.</p>
      <p>Man kann  auch das Alter der Gesteine aus der Karte bzw. der Kartenlegende herauslesen.
        Zusätzlich finden sich  auch Informationen (Pfeile, Linien) zur  Lagerung der Gesteine,  ob es Brüche  oder andere  geologische Störzonen gibt.
      </p>
    </div>


  </div>
</template>

<script>
export default {
  name: "WasIstGeologischeKarte2"
}
</script>

<style scoped>

</style>