<template>
  <div class="container-fluid pt-4">
    <div class="container-fluid text-left">
      <img src="/img/erdgeschichte/Schlier_Schotter2.png" class="float-end ms-3">
      <div>
        <p>Die heutige Landschaft des Gemeindegebiets entstand in den letzten rund eine Million Jahren. Mächtige und weite Schotterkörper prägten das Bild im
          Raum Timelkam  in der Zeit vor der Eiszeit.</p>
        <p>Sie wurden von den Flüssen aus den Alpen, aber auch durch die Abtragung und wiederholte Umlagerung der Hausruckschotter gegen Süden hin, geschaffen. Die Höhen von Obereck und Altwartenburg sind Reste dieser Schotterkörper.</p>


      </div>
      <div>
        <img src="/img/erdgeschichte/Schlier_Schotter1.png" class="me-3" style="max-width:40%; float:left;">
        <p>Über dem Schlier bei der Busselalm ist der Schotter deutlich zu erkennen.</p>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "Erdgeschichte5"
}
</script>

<style scoped>

</style>