<template>
  <div class="container-fluid pt-4">

    <img src="/img/schichten/Hochterrasse.jpg" style="max-width:50%;float:right;margin-left:20px;"/>
    <div class="container-fluid text-left">
      <p>Die <b>Hochterrasse</b> entstand während der <b>Riss-Eiszeit</b>.  Die Gletscher erreichten  ihre größte Ausdehnung vor 300.000 Jahren und dauerte wieder über 100.000 Jahre.
      </p>

      <p>Während die Gletscher der Günz- und Mindeleiszeit über unser Gemeindegebiet hinausreichten, berührt die Riss-Endmoräne unser Gemeindegebiet kaum, sie liegt zwischen Weiterschwang, Koberg und Gampern. Die aus dem Süden stammenden Schotter bestehen aus Kalkgestein der Nördlichen Kalkalpen und Sandsteinen aus der Flyschzone, aber auch aus Quarz und kristallinen Gesteinen aus den vorhergehenden Ablagerungen. Die Hochterrassenkörper des Ampflwanger- und Redlbaches bestehen aus nochmals umgelagertem quarzreichen Kies. An manchen Stellen sind die Gerölle so stark verkittet, dass Konglomerate gebildet werden (an der Straße Gallaberg – Lenzing)
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Hochterrasse"
}
</script>

<style scoped>

</style>