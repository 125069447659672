<template>
  <div class="d-flex flex-column justify-content-around">

    <div>
      <div class="mb-3">
        <button type="button" class="btn btn-outline-secondary" @click="$emit('change-page',{index:'Erdöl und Erdgas'})">
          <h2>Bohrungen</h2>
        </button>
      </div>

      <div class="mb-3">
        <button type="button" class="btn btn-outline-secondary" @click="$emit('change-page',{index:'Bad Timelkam?'})">
          <h2>Bad Timelkam?</h2>
        </button>
      </div>
    </div>
    </div>

</template>

<script>
export default {
  name: "Rohstoffe"
}
</script>

<style scoped>

</style>