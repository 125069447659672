<template>
  <div class="container-fluid pt-4">
    <div class="container-fluid text-left">
      <div class="d-flex">
        <div>
          <p>Als sich das Meer (<a href="#" @click.prevent="$emit('change-page',{index:'Das Meer kommt zurück'})">Parathetys</a>) vor über 15 Millionen Jahren zurückzog, bildete der wasserdichte Schlier die Landoberfläche.</p>
          <p>In der darauffolgenden Zeit schnitten Flüsse breite Täler im Schlier aus. Diese Täler finden wir noch im Untergrund, man bezeichnet sie als „Schlierrinnen“ oder „Schlierwannen“.  Durch die Alpenhebung lagerten sich darüber wieder Schotter ab.</p>
          <p>Die heutigen Flüsse folgen nur grob diesem alten Flusssystem, manchmal kann das Grundwasser auch in eine andere Richtung fließen als das Oberflächenwasser.</p>

        </div>
        <div class="ms-3">
          <img src="/img/wasser/Grundwasserkarte.png" style="width:400px"/>

          <p class="small">Grundwasserkarte der  Gemeinde - Die grünen Linien sind die Schichtlinien der Schlieroberfläche</p>

        </div>
      </div>
      <p class="medium">Aus: FLÖGL, H. (1983): Wasserwirtschaftliches Grundsatzgutachten Vöckla – Ager – Traun – Alm. Karte: Schlier - und Flyschrelief  unter eiszeitlichen Schottern.  Amt  d. OÖ Landesregierung, Abteilung Wasserbau. Linz</p>




    </div>
  </div>
</template>

<script>
export default {
  name: "GrundwasserFinden"
}
</script>

<style scoped>

</style>