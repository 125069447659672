<template>
  <div class="container-fluid text-left pt-4">
    <p>Hinweis: Die große Tafel gibt eine bessere Übersicht!</p>
    <p>Die weißen, grünen, rosa und gelben Farben sind scheinbar sehr ungleichmäßig im Gemeindegebiet verteilt.
      Bei genauerer Betrachtung merkt man aber, dass sich die <b>weißen Farbflächen</b> längs der Flüsse bzw. Bäche im Gemeindegebiet befinden. <b>Damit sind die Ablagerungen aus dem jüngsten geologischen Zeitalter (Holozän) gekennzeichnet</b>.
    </p>

    <p>Mit den unterschiedlichen <b>gelben Farben</b> sind Ablagerungen (Schotter) aus den <b>Eiszeiten</b> und aus der Zeit <b>vor den Eiszeiten dargestellt</b>. Auch die Ablagerungen mit der <b>rosa Farbe</b> gehören dazu, es sind meist feinkörnige Ablagerungen in Hanglage, sind also anders entstanden.
    </p>

    <p>Mit <b>grün</b> werden die ältesten Schichten dargestellt. Sie liegen unter  den zuvor genannten Schichten und werden allgemein <b>„Schlier“</b> bzw. „Schliersand“ genannt.  Diese Meeresablagerungen stammen aus dem <b>Neogen</b> (früher sagte man dazu auch „Jung-Tertiär“). Sie treten an der Basis aller Timelkamer Höhenzüge (Obereck, Koberg, Wartenburg…) auf. Ihre Schichtoberfläche bildet wannenartige Vertiefungen, die als Grundwasserspeicher hohe Bedeutung haben.
    </p>
  </div>
</template>

<script>
export default {
  name: "WasKannManErkennen"
}
</script>

<style scoped>

</style>