<template>
  <div class="container-fluid pt-3">

    <img src="/img/karte/GeologischeKarte_1.png" style="width:75%;"/>

  </div>

</template>

<script>
export default {
  name: "WasIstGeologischeKarte1"
}
</script>

<style scoped>

</style>