<template>
  <div class="container-fluid pt-4">

    <div class="container-fluid text-left">
      <div class="d-flex">
        <div>
          <p>Die Ablagerungen des <b>Holozän</b>, der geologischen Jetztzeit (die letzten 10.000 Jahre) finden wir entlang unserer Flüsse. Besonders breit sind diese Sand- und Schotterflächen zwischen Stöfling, Oberau und der OKA-Siedlung ausgebildet. Auch der Marktturm steht auf den jüngsten Ablagerungen der Dürren Ager.</p>
          <p>Auf dem Foto sieht man, dass ein Teil von Eiding auf der Niederterrasse liegt, der andere Teil bei der Vöckla in den holozänen Ablagerungen.</p>
        </div>
        <div class="ms-3">
          <img src="/img/schichten/Holozaen.jpg" class="img-fluid"/>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "Holozaen"
}
</script>

<style scoped>

</style>